import React from "react";
import IndiaSection1 from "./pages/IndiaSection1";
import IndiaSection2 from "./pages/IndiaSection2";
import IndiaSection3 from "./pages/IndiaSection3";
import IndiaSection4 from "./pages/IndiaSection4";

const MainPageIndia = () => {
  return (
    <>
      <IndiaSection1 />
      <IndiaSection2 />
      <IndiaSection3 />
      <IndiaSection4 />
    </>
  );
};

export default MainPageIndia;
