import React from "react";
import "../Styles/CRISection1.css";

const CRISection1 = () => {
  return (
    <>
      <div className="criSectionFirst">
        <div className="container conatiner_criSectionFirst">
          <h2>Climate Resilience Initiative</h2>
        </div>
      </div>
    </>
  );
};

export default CRISection1;
