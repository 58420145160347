import React from "react";
import GRISection1 from "./pages/GRISection1";
import GRISection2 from "./pages/GRISection2";
import GRISection3 from "./pages/GRISection3";
import GRISection4 from "./pages/GRISection4";

const MainGRIPage = () => {
  return (
    <>
      <GRISection1 />
      <GRISection2 />
      <GRISection3 />
      <GRISection4 />
    </>
  );
};

export default MainGRIPage;
