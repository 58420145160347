import React from "react";
import "../styles/GRISection1.css";
const GRISection1 = () => {
  return (
    <>
      <div className="griSection1">
        <div className="container container_griSection1">
          <h1>Grassroots Resilience Institute (GRI)</h1>
        </div>
      </div>
    </>
  );
};

export default GRISection1;
