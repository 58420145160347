import React from "react";
import NepalSection1 from "./pages/NepalSection1";
import NepalSection2 from "./pages/NepalSection2";
import NepalSection3 from "./pages/NepalSection3";
import NepalSection4 from "./pages/NepalSection4";
import NepalSection5 from "./pages/NepalSection5";
import NepalSection6 from "./pages/NepalSection6";
import NepalSection7 from "./pages/NepalSection7";

const MainPageNepal = () => {
  return (
    <div>
      <NepalSection1 />
      <NepalSection2 />
      <NepalSection3 />
      <NepalSection4 />
      <NepalSection5 />
      <NepalSection6 />
      <NepalSection7 />
    </div>
  );
};

export default MainPageNepal;
