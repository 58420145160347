import React from "react";
import Section1 from "./section_1/Section1";
import Section2 from "./section_2/Section2";
import Section3 from "./section_3/Section3";
import Section4 from "./section4/Section4";
import Section5 from "./section5/Section5";
import Section6 from "./section6/Section6";

const MainHomePage = () => {
  return (
    <>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
    </>
  );
};

export default MainHomePage;
