import React from "react";
import "../styles/FirstHomePageSection.css";
const FirstHomePageSection = () => {
  return (
    <>
      <div className="firstHomePageSection">
        <div className="container">
          <h1>Who we are</h1>
        </div>
      </div>
    </>
  );
};

export default FirstHomePageSection;
