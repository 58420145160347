import React from "react";
import "../styles/PhilippinesSection1.css";
const PhilippinesSection1 = () => {
  return (
    <>
      <div className="PhilippinesSection1">
        <div className="container conatiner_PhilippinesSection1">
          <h1>Nepal</h1>
          <p>
            PCII operates two initiatives in Bangladesh: Migrants Resilience
            <br />
            Collaborative and the Climate Resilience Initiative.
          </p>
        </div>
      </div>
    </>
  );
};

export default PhilippinesSection1;
