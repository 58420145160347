import React from "react";
import CRISection1 from "./pages/CRISection1";
import CRISection2 from "./pages/CRISection2";
import CRISection3 from "./pages/CRISection3";
import CRISection4 from "./pages/CRISection4";
import CRISection5 from "./pages/CRISection5";

const MainCRIComponent = () => {
  return (
    <div>
      <CRISection1 />
      <CRISection2 />
      <CRISection3 />
      <CRISection4 />
      <CRISection5 />
    </div>
  );
};

export default MainCRIComponent;
