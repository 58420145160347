import React from "react";
import IndonesiaSection1 from "./pages/IndonesiaSection1";
import IndonesiaSection2 from "./pages/IndonesiaSection2";
import IndonesiaSection3 from "./pages/IndonesiaSection3";
import IndonesiaSection4 from "./pages/IndonesiaSection4";
import IndonesiaSection5 from "./pages/IndonesiaSection5";
import IndonesiaSection6 from "./pages/IndonesiaSection6";
import IndonesiaSection7 from "./pages/IndonesiaSection7";
import IndonesiaSection8 from "./pages/IndonesiaSection8";

const IndonesiaMainPage = () => {
  return (
    <>
      <IndonesiaSection1 />
      <IndonesiaSection2 />
      <IndonesiaSection3 />
      <IndonesiaSection4 />
      <IndonesiaSection5 />
      <IndonesiaSection6 />
      <IndonesiaSection7 />
      <IndonesiaSection8 />
    </>
  );
};

export default IndonesiaMainPage;
