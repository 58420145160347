import React from "react";
import "../styles/BangladeshSection1.css";
const BangladeshSection1 = () => {
  return (
    <>
      <div className="bangladeshSection1">
        <div className="container conatiner_bangladeshSection1">
          <h1>Bangladesh</h1>
          <p>
            PCII operates two initiatives in Bangladesh: Migrants Resilience
            <br />
            Collaborative and the Climate Resilience Initiative.
          </p>
        </div>
      </div>
    </>
  );
};

export default BangladeshSection1;
